<template>
    <div v-loading="$waiting.is('loading')">
        <el-dialog
            width="1200px"
            top="40px"
            title="Redigera betalningar"
            :visible="visible"
            :close-on-click-modal="false"
            :before-close="resetFormAndClose"
            @closed="resetForm"
            :lock-scroll="false"
            @open="getDetails"
            class="modal-edit-payment-container"
        >
            <div class="px-10" v-loading="$waiting.is('loading')">
                <el-tag size="medium" effect="dark" v-if="invoiceData.hasDebt" type="danger" class="mb-1 mt-1">
                    <i class="fa-solid fa-skull-crossbones" /> {{ invoiceData.invoice.clientName }} har en skuld på {{ invoiceData.debtAmount }} SEK till Bolageriet
                </el-tag>
                <div v-if="invoiceData.invoice.collection">
                    <p class="mb-2 p-3 rounded-xl text-center font-semibold bg-red-400 text-white">Pågående inkassoärende</p>
                </div>
                <p class="ml-2 text-lg">
                    Vald faktura
                </p>
                <div class="relative border rounded-lg py-4 px-5 mb-4 grid grid-cols-3 gap-6">
                    <div>
                        <p class="font-bold text-base border-b pb-2 mb-4">{{ invoiceData.invoice.invoiceType }} {{ invoiceData.invoice.invoiceNumber }}</p>
                        <el-descriptions :column="1">
                            <el-descriptions-item v-if="invoiceData.invoice.headInvoiceNumber != null" label="Krediterar faktura">{{ invoiceData.invoice.headInvoiceNumber }}</el-descriptions-item>
                            <el-descriptions-item label="Klient">{{ invoiceData.invoice.clientName }}</el-descriptions-item>
                            <el-descriptions-item label="Kund">{{ invoiceData.invoice.customerName }}</el-descriptions-item>
                            <el-descriptions-item label="Ocr">{{ invoiceData.invoice.ocr }}</el-descriptions-item>
                            <el-descriptions-item label="Fakturadatum">{{ formatDate(invoiceData.invoice.invoiceDate) }}</el-descriptions-item>
                            <el-descriptions-item label="Förfallodatum">{{ formatDate(invoiceData.invoice.dueDate) }}</el-descriptions-item>
                            <el-descriptions-item label="Kapitalbelopp">{{ invoiceData.invoice.capitalAmount }} SEK </el-descriptions-item>
                            <el-descriptions-item v-if="hasInvoiceCurrency" label="Kvar att betala"> {{ invoiceData.invoice.originalRestAmount }} {{ invoiceData.invoice.currency }} </el-descriptions-item>
                            <el-descriptions-item label="Kvar att betala">{{ invoiceData.invoice.restAmount }} SEK</el-descriptions-item>
                            <el-descriptions-item label="Avgift">{{ reminderFeeSum }} {{ invoiceData.invoice.currency }}</el-descriptions-item>
                            <el-descriptions-item label="Ränta">{{ interestAmountSum }} {{ invoiceData.invoice.currency }}</el-descriptions-item>
                            <el-descriptions-item label="Status">
                                <el-tag type="success">{{ invoiceData.invoice.status }}</el-tag>
                            </el-descriptions-item>
                        </el-descriptions>
                        <Reminders v-if="invoiceData.reminders.length > 0" :invoice="invoiceData" />
                    </div>
                    <div>
                        <Ledger :ledger-data="invoiceData.salesLedgers" />
                    </div>
                    <div>
                        <Events :events-data="invoiceData.invoice.events" />
                    </div>
                </div>

                <div v-if="invoiceData.otherMatches.length">
                    <p class="ml-2 text-lg">
                        Andra länkade fakturor
                    </p>
                    <div class="border-green-500 border-2 rounded-lg p-6 mb-4">
                        <el-table :data="invoiceData.otherMatches">
                            <el-table-column label="Förfallodatum" width="120">
                                <template slot-scope="scope">
                                    {{ formatDate(scope.row.dueDate) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Faktura datum" width="120">
                                <template slot-scope="scope">
                                    {{ formatDate(scope.row.invoiceDate) }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="invoiceNumber" label="Nr" width="70" />
                            <el-table-column label="Betalt" width="110" align="right">
                                <template slot-scope="scope">
                                    {{ scope.row.paidAmount | swedishNumberFormat }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Kapitalbelopp" width="110" align="right">
                                <template slot-scope="scope">
                                    {{ scope.row.capitalAmount | swedishNumberFormat }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="clientName" label="Klient namn" show-overflow-tooltip />
                            <el-table-column prop="customerName" label="Kund namn" show-overflow-tooltip />
                        </el-table>
                    </div>
                </div>
                <p class="ml-2 text-lg">
                    Matcha med betalning
                </p>
                <div class="relative border rounded-lg py-4 px-5 mb-4 grid grid-cols-2 gap-6">
                    <div>
                        <el-row :gutter="10">
                            <el-col :span="12">
                                <p class="font-bold text-base border-b pb-2 mb-4">Betalningsinformation</p>
                                <el-descriptions :column="1">
                                    <el-descriptions-item label="Betalare">{{ invoiceData.payment.payer }}</el-descriptions-item>
                                    <el-descriptions-item label="Inbetalt belopp">{{ invoiceData.payment.amount }} SEK</el-descriptions-item>
                                    <el-descriptions-item v-if="hasCurrency" label="Inbetalt belopp">{{ invoiceData.payment.originalAmount }} {{ invoiceData.payment.currency }}</el-descriptions-item>
                                    <el-descriptions-item label="Ocr">{{ invoiceData.payment.ocr }}</el-descriptions-item>
                                    <el-descriptions-item label="Meddelande">{{ invoiceData.payment.message }}</el-descriptions-item>
                                </el-descriptions>
                            </el-col>
                            <el-col :span="12">
                                <p class="font-bold text-base border-b pb-2 mb-4">Faktura</p>
                                <el-descriptions :column="1">
                                    <el-descriptions-item label="Kund">{{ invoiceData.invoice.customerName }}</el-descriptions-item>
                                    <el-descriptions-item label="Kvar att betala">{{ invoiceData.invoice.restAmount }} SEK</el-descriptions-item>
                                    <el-descriptions-item v-if="hasInvoiceCurrency" label="Kvar att betala">
                                        {{ invoiceData.invoice.originalRestAmount }} {{ invoiceData.invoice.currency }}
                                    </el-descriptions-item>
                                    <el-descriptions-item label="Ocr">{{ invoiceData.invoice.ocr }}</el-descriptions-item>
                                    <el-descriptions-item label="Fakturanr.">{{ invoiceData.invoice.invoiceNumber }}</el-descriptions-item>
                                </el-descriptions>
                            </el-col>
                        </el-row>
                    </div>
                    <div>
                        <el-form label-position="left" label-width="230px">
                            <el-form-item v-if="invoiceData.hasDebt" label="Skuld till Bolageriet">
                                <el-checkbox v-model="hasDebt" @change="onDebtChange">Dra av skuld</el-checkbox>
                            </el-form-item>
                            <el-form-item v-if="hasDebt && invoiceData.hasDebt" label="Skuld (2440)">
                                <InputCalc class="w-full" v-model="debtAmount" :controls="false" :precision="2" />
                            </el-form-item>
                            <el-form-item label="Restbelopp">
                                <el-input v-model="restAmount" disabled />
                            </el-form-item>

                            <el-form-item label="Kundfordran (1510)">
                                <InputCalc v-model="accountsReceivable1510" :precision="2" custom-class="w-full text-sm py-1 px-2 rounded border border-solid input-border" />
                            </el-form-item>

                            <el-form-item v-if="showFeeInputs" label="Påminelseavgift (3580)">
                                <InputCalc class="w-full" v-model="reminderFee" :controls="false" :precision="2" />
                            </el-form-item>
                            <el-form-item v-if="showFeeInputs" label="Ränta (8313)">
                                <InputCalc class="w-full" v-model="interestAmount" :controls="false" :precision="2" />
                            </el-form-item>
                            <el-form-item v-if="!hasCurrency" label="Öresavrundning (3740)">
                                <InputCalc class="w-full" v-model="pennyRounding" :controls="false" :precision="2" />
                            </el-form-item>
                            <el-form-item v-if="hasCurrency" label="Valutadiff (3960/7960)">
                                <el-input class="w-full" v-model="exchangeRate" />
                            </el-form-item>
                            <el-form-item v-if="hasCurrency" label="Valutahanteringsavgift (7960)">
                                <el-input v-model="currencyHandlingFee" disabled />
                            </el-form-item>
                            <el-form-item label="Bankkonto (1930)">
                                <el-input v-model="bankAmount" disabled />
                            </el-form-item>
                        </el-form>
                        <el-button @click="closeModal" :loading="$waiting.is('loading.matching')">Stäng</el-button>
                        <el-button v-if="invoiceData.isMatched" @click="unmatchInvoice" type="primary" :loading="$waiting.is('loading.matching')">Unmatch</el-button>
                        <el-button @click="matchInvoice" type="primary" :loading="$waiting.is('loading.matching')">Match/Updatera</el-button>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import moment from "moment";
import Api from "../avstamning.api";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        paymentId: {
            type: Number,
            default: null,
        },
        invoiceId: {
            type: Number,
            default: null,
        },
    },

    components: {
        Events: () => import("../components/Events.vue"),
        Reminders: () => import("../components/Reminders.vue"),
        Ledger: () => import(/* webpackChunkName: "InvoiceDetailsLedger" */ "../components/Ledger.vue"),
        InputCalc: () => import(/* webpackChunkName: "BaseInputNumericCalc" */ "@/components/baseInputNumericCalc.vue"),
    },

    data() {
        return {
            invoiceData: {
                invoice: {},
                payment: {},
                reminders: [],
                otherMatches: [],
            },
            reminderFee: 0,
            interestAmount: 0,
            accountsReceivable1510: 0,
            debtAmount: 0,
            restAmount: 0,
            bankAmount: 0,
            exchangeRate: 0,
            pennyRounding: 0,
            currencyHandlingFee: 0,
            hasDebt: false,
        };
    },

    watch: {
        accountsReceivable1510() {
            this.calcRestAmount();
        },
        debtAmount() {
            this.calcRestAmount();
        },
        interestAmount() {
            this.calcRestAmount();
        },
        pennyRounding() {
            this.calcRestAmount();
        },
        reminderFee() {
            this.calcRestAmount();
        },
        exchangeRate() {
            this.calcRestAmount();
        },
    },

    computed: {
        paymentMessages() {
            // split nessag by space
            if (this.invoiceData.payment.message == null) return [];
            const messages = this.invoiceData.payment.message.split(" ");
            let result = [];
            messages.forEach(element => {
                result.push(element);
            });
            return result;
        },
        showFeeInputs() {
            if (this.overPayment && (this.reminderFeeSum > 0 || this.interestAmountSum > 0)) {
                return true;
            } else {
                return false;
            }
        },
        reminderFeeSum() {
            const fee = this.invoiceData.reminders.reduce((acc, item) => acc + item.reminderFee, 0);
            return fee;
        },
        interestAmountSum() {
            const fee = this.invoiceData.reminders.reduce((acc, item) => acc + item.interestAmount, 0);
            return fee;
        },
        hasCurrency() {
            if (this.invoiceData.payment.currency == null || this.invoiceData.payment.currency == "" || this.invoiceData.payment.currency == "SEK") {
                return false;
            }
            return true;
        },
        hasInvoiceCurrency() {
            console.log("🚀 - file: ModalEditPayment.vue:248 - hasInvoiceCurrency - this.invoiceData.invoice.currency:", this.invoiceData.invoice.currency);
            if (this.invoiceData.invoice.currency == null || this.invoiceData.invoice.currency == "" || this.invoiceData.invoice.currency == "SEK") {
                return false;
            }
            return true;
        },
        partlyPayment() {
            if (this.hasCurrency) {
                return this.invoiceData.payment.originalAmount < this.invoiceData.invoice.originalRestAmount;
            } else {
                return this.invoiceData.payment.amount < this.invoiceData.invoice.restAmount;
            }
        },
        overPayment() {
            if (this.hasCurrency) {
                return this.invoiceData.payment.originalAmount > this.invoiceData.invoice.originalRestAmount;
            } else {
                return this.invoiceData.payment.amount > this.invoiceData.invoice.restAmount;
            }
        },
    },

    methods: {
        calcRestAmount() {
            if (!this.invoiceData.restAmount) return 0;
            const paymentLeft = this.invoiceData.payment.amount - this.invoiceData.otherPaid;
            const debtAmount = parseFloat(this.debtAmount);
            const currencyHandlingFee = parseFloat(this.currencyHandlingFee);
            const accountsReceivable1510 = parseFloat(this.accountsReceivable1510);
            const reminderFee = parseFloat(this.reminderFee);
            const interestAmount = parseFloat(this.interestAmount);
            const pennyRounding = parseFloat(this.pennyRounding);
            const exchangeRate = parseFloat(this.exchangeRate);
            console.log("🚀 - file: ModalEditPayment.vue:273 - calcRestAmount - this.invoiceData.restAmount:", this.invoiceData.restAmount, accountsReceivable1510);
            this.restAmount = (paymentLeft.x100() - exchangeRate.x100() - reminderFee.x100() - interestAmount.x100() - accountsReceivable1510.x100() - pennyRounding.x100()) / 100;
            // this.bankAmount = (this.invoiceData.restAmount.x100() - this.restAmount.x100() - debtAmount.x100() - currencyHandlingFee.x100()) / 100;
            this.bankAmount = (paymentLeft.x100() - this.restAmount.x100() - debtAmount.x100() - currencyHandlingFee.x100()) / 100;
        },

        onDebtChange() {
            if (this.hasDebt) {
                this.debtAmount = this.invoiceData.debtAmount > this.invoiceData.payment.amount ? this.invoiceData.payment.amount : this.invoiceData.debtAmount;
            } else {
                this.debtAmount = 0;
            }
        },

        async getDetails() {
            this.$waiting.start("loading");
            const invoiceData = await Api.getInvoiceDetails(this.paymentId, this.invoiceId);
            const paymentLeft = invoiceData.payment.amount - invoiceData.otherPaid;
            this.invoiceData = invoiceData;
            this.reminderFee = invoiceData.reminderFee;
            this.pennyRounding = invoiceData.pennyRounding;
            this.exchangeRate = invoiceData.exchangeRate;
            this.interestAmount = invoiceData.interestAmount;
            this.accountsReceivable1510 = invoiceData.invoicePayment;

            //this.currencyHandlingFee = this.hasCurrency ? Math.floor(invoiceData.payment.amount * 0.01) : 0;

            if (this.hasCurrency) {
                if (invoiceData.invoice.originalRestAmount == invoiceData.payment.originalAmount) {
                    this.accountsReceivable1510 = invoiceData.invoice.restAmount;
                    this.exchangeRate = (invoiceData.payment.amount.x100() - invoiceData.invoice.restAmount.x100()) / 100;
                } else if (invoiceData.invoice.originalRestAmount < invoiceData.payment.originalAmount) {
                    const originalDiff = (invoiceData.payment.originalAmount.x100() - invoiceData.invoice.originalRestAmount.x100()) / 100;
                    const exchangeRate = invoiceData.payment.amount / invoiceData.payment.originalAmount;
                    if (this.reminderFeeSum > 0) {
                        if (originalDiff > 6) {
                            this.reminderFee = 60;
                            this.interestAmount = ((originalDiff - 6) * exchangeRate).toDecimals(2);
                            this.accountsReceivable1510 = invoiceData.invoice.restAmount;
                        }
                        this.exchangeRate = (invoiceData.payment.amount.x100() - this.reminderFee.x100() - this.interestAmount.x100() - invoiceData.invoice.restAmount.x100()) / 100;
                    } else {
                        this.accountsReceivable1510 = (invoiceData.invoice.restAmount + originalDiff * exchangeRate).toDecimals(2);
                        this.exchangeRate = (invoiceData.payment.amount.x100() - this.accountsReceivable1510.x100()) / 100;
                    }
                } else if (invoiceData.invoice.originalRestAmount > invoiceData.payment.originalAmount) {
                    this.exchangeRate = (invoiceData.payment.amount.x100() - this.reminderFee.x100() - this.interestAmount.x100() - this.accountsReceivable1510.x100()) / 100;
                    this.accountsReceivable1510 = ((invoiceData.payment.originalAmount.x100() / invoiceData.invoice.originalRestAmount.x100()) * invoiceData.invoice.restAmount.x100()) / 100;
                }
            } else {
                this.exchangeRate = 0;
                if (invoiceData.invoice.restAmount >= paymentLeft) {
                    this.accountsReceivable1510 = paymentLeft;
                } else if (invoiceData.invoice.restAmount < paymentLeft) {
                    let originalDiff = (paymentLeft.x100() - invoiceData.invoice.restAmount.x100()) / 100;
                    if (originalDiff > 0) {
                        this.reminderFee = originalDiff >= this.reminderFeeSum ? this.reminderFeeSum : originalDiff;
                        originalDiff = (originalDiff.x100() - this.reminderFee.x100()) / 100;
                    }
                    if (originalDiff > 0) {
                        this.interestAmount = originalDiff >= this.interestAmountSum ? this.interestAmountSum : originalDiff;
                        originalDiff = (originalDiff.x100() - this.interestAmount.x100()) / 100;
                    }
                    if (originalDiff < 10) {
                        this.pennyRounding = originalDiff;
                        originalDiff = 0;
                    }
                    this.accountsReceivable1510 = (this.accountsReceivable1510.x100() + originalDiff.x100()) / 100;
                }
            }
            //this.hasDebt = invoiceData.hasDebt;
            if (this.hasDebt) {
                this.debtAmount = invoiceData.debtAmount > invoiceData.payment.amount ? invoiceData.payment.amount : invoiceData.debtAmount;
            }
            this.$waiting.end("loading");
        },

        async matchInvoice() {
            this.$waiting.start("loading.matching");
            const invoiceData = {
                reminderFee: this.reminderFee,
                interestAmount: this.interestAmount,
                invoicePayment: this.accountsReceivable1510,
                debtAmount: this.debtAmount,
                bankAmount: this.bankAmount,
                pennyRounding: this.pennyRounding,
                exchangeRate: this.exchangeRate,
            };
            await Api.matchInvoice(this.paymentId, this.invoiceId, invoiceData);
            this.$notify.success({ title: "Utfört" });
            this.$emit("refresh");
            this.$waiting.end("loading.matching");
            this.closeModal();
        },

        async unmatchInvoice() {
            this.$waiting.start("loading.matching");
            await Api.removeMatchingFromInvoice(this.paymentId, this.invoiceId);
            this.$notify.success({ title: "Utfört" });
            this.$emit("refresh");
            this.$waiting.end("loading.matching");
            this.closeModal();
        },

        formatDate(date) {
            return date != null ? moment(date).format("YYYY-MM-DD") : "";
        },

        resetFormAndClose() {
            this.resetForm();
            this.closeModal();
        },

        resetForm() {
            this.invoiceData = {
                invoice: {},
                payment: {},
                reminders: [],
                otherMatches: [],
            };
            this.reminderFee = 0;
            this.interestAmount = 0;
            this.accountsReceivable1510 = 0;
            this.debtAmount = 0;
        },

        closeModal() {
            this.$emit("close");
        },
    },
};
</script>
<style>
.my-content:not(:last-child) {
    /* background: #e1f3d8; */
    margin-right: 3px;
}

.modal-edit-payment-container .el-input-number .el-input__inner {
    text-align: left !important;
}
.el-descriptions--small:not(.is-bordered) .el-descriptions-item__cell {
    padding-bottom: 1px;
}
</style>
